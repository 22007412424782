import { useMediaQuery } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Card, Stack } from "react-bootstrap";
import DashboardSidebar from "../navbar/DashboardSidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Avatar, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AuthContext } from "../../context/AuthContext";
import AssignmentIcon from "@mui/icons-material/Assignment";

const RecommendationPage = () => {
  const { logout } = useContext(AuthContext);

  const isMobile = useMediaQuery("(max-width:1000px)");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [author, setAuthor] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [image, setImage] = useState("");
  const token = Cookies.get("authToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);

  useEffect(() => {
    const query = `
      query {
        recommendation(id: "${id}") {
          title
          shortDescription
          thumbnail
          author
          content
          username
        }
      }
    `;

    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query },
        {
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data.data.recommendation;
        if (data) {
          setTitle(data.title);
          setShortDescription(data.short_description);
          setImage(data.thumbnail);
          setDescription(data.content);
          setAuthor(data.author);
          setUsername(data.username);
        }
      })
      .catch((error) => {
        console.error("Error fetching recommendation:", error);
      });
  }, [id, token]);

  if (isMobile) {
    return (
      <Container
        fluid
        style={{
          width: "100%",
          backgroundColor: "#FFFDF2",
          overflowX: 0,
          overflowY: 0,
        }}
      >
        <Row>
          <DashboardSidebar isBack={true} />
        </Row>
        <Row>
          <Col
            style={{
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              backgroundImage: `url(${image})`, // Replace with your image URL
              boxShadow: "0 4px 20px rgba(0, 0, 0, 1)",
              height: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Align items at the bottom
              backgroundSize: "cover", // Ensure the image covers the whole area
              backgroundRepeat: "no-repeat", // Prevent repeating the image
              backgroundPosition: "center", // Center the image
            }}
          >
            <div
              style={{
                // marginBottom: "10px",
                fontFamily: "Poppins",
                color: "#fff",
              }}
            >
              <h2 style={{ margin: 0, fontSize: "24px", fontWeight: "bold" }}>
                {title}
              </h2>
              <h5 style={{ margin: "10px 0", fontSize: "14px" }}>
                {shortDescription}
              </h5>
              <h5
                className="mt-2"
                style={{ margin: "10px 0", fontSize: "14px" }}
              >
                Autor: {author}
              </h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              boxShadow: "0px -10px 10px gray",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              overflowY: "auto", // Enable vertical scrolling
              overflowX: "hidden", // Hide horizontal overflow
              maxHeight: "600px", // Set max height to avoid overflow
              whiteSpace: "pre-wrap", // Allow text to wrap to the next line
            }}
            className="text-center"
          >
            {description.split("\n").map((htmlContent, index) => (
              <Typography
                key={index}
                sx={{ fontFamily: "Poppins", mb: 2 }}
                dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML content
              />
            ))}
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid style={{ backgroundColor: "#E7EFDF" }}>
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandări
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>
          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
                marginRight: 10,
                borderRadius: "40px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Card
                className="rounded-3 mb-2"
                style={{
                  backgroundColor: "#FFFEF7",
                  fontFamily: "Poppins",
                  border: "none",
                  boxShadow: "5px 5px 5px 5px #f0f0f0",
                }}
              >
                <Row className="g-0">
                  <Col xs={6} md={4} className="d-flex align-items-center">
                    <Card.Img
                      variant="left"
                      src={image}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    />
                  </Col>
                  <Col xs={6} md={8} className="d-flex flex-column">
                    <Card.Body className="p-3 d-flex flex-column">
                      <div>
                        <Card.Title
                          className="fs-6"
                          style={{ fontWeight: "bold" }}
                        >
                          {title} {/* Title */}
                        </Card.Title>
                        <Card.Text style={{ fontSize: "14px" }}>
                          {shortDescription}
                        </Card.Text>
                      </div>
                      <h5
                        className="mt-auto"
                        style={{ margin: "10px 0", fontSize: "14px" }}
                      >
                        Autor: {author}
                      </h5>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              <Row className="mt-2">
                {description.split("\n").map((htmlContent, index) => (
                  <Typography
                    key={index}
                    sx={{ fontFamily: "Poppins", mb: 2 }}
                    dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML content
                  />
                ))}
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default RecommendationPage;
