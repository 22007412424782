import { Alert, Avatar, Skeleton, Typography } from "@mui/material";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Card,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import "./DashboardSidebar.css";
import RecipeCardBig from "../../components/cards/RecipeCardBig";
import { Box, useMediaQuery } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BlackButton from "../../components/buttons/BlackButton";
import FancyCheckbox from "../../components/buttons/FancyCheckbox";

const ShoppingList = ({}) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [ingredientNames, setIngredientNames] = useState([]);
  const [username, setUsername] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [amountUnits, setAmountUnits] = useState([]);
  const { logout } = useContext(AuthContext);
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const token = Cookies.get("authToken");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1); // State to keep track of the current page
  const [hasMore, setHasMore] = useState(true); // State to manage loading more items
  const [email, setEmail] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [allLoaded, setAllLoaded] = useState(false); // Track if all ingredients are loaded
  const [ingredientsLoading, setIngredientsLoading] = useState(false);

  const getShoppingList = () => {
    setIngredientsLoading(true);

    // Check if shopping list is already in sessionStorage
    const existingShoppingList = sessionStorage.getItem("shoppingList");
    if (existingShoppingList) {
      // If data exists, parse and set it to the state
      const parsedShoppingList = JSON.parse(existingShoppingList);
      setIngredients(parsedShoppingList.shoppingList);
      setUsername(parsedShoppingList.username);
      setIngredientsLoading(false); // Stop loading if data is already available
      return; // Exit early since we have the data
    }

    // Make the API call if no data exists in sessionStorage
    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        {
          query: `mutation { generateShoppingList { shoppingList { aisle ingredients { name normalizedName unit amount } } message username email } }`,
        },
        {
          headers: {
            Authorization: `JWT ` + token,
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      )
      .then((response) => {
        const shoppingListData = response.data.data.generateShoppingList;
        setIngredients(shoppingListData.shoppingList);
        setUsername(shoppingListData.username);
        setEmail(shoppingListData.email);

        // Update sessionStorage with the new shopping list data
        sessionStorage.setItem(
          "shoppingList",
          JSON.stringify(shoppingListData)
        );

        setIngredientsLoading(false);
      })
      .catch((error) => {
        console.error("Error here:", error);
        setIngredientsLoading(false); // Ensure loading state is reset on error
      });
  };

  useEffect(() => {
    console.log("ShoppingList.js: useEffect");
    getShoppingList(); // Fetch shopping list when page changes
  }, []);

  const handleEmail = () => {
    setModalLoading(true);
    let query = `
    mutation ShoppingListFeedbackMutation($email: String!) { shoppingListFeedback( email: $email) { success error } }`;
    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query: query, variables: { email: email } },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("a mers");
      })
      .catch((error) => {
        console.log("nu a mers");
      })
      .finally(() => {
        setModalLoading(false);
        handleClose();
      });
  };

  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFEF7" }}>
        <Row>
          <DashboardSidebar />
        </Row>

        <Row>
          <Col
            style={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              backgroundColor: "#FFFEF7",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)", // More pronounced box shadow
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Cumpărături
            </Typography>
          </Col>
        </Row>

        <Row
          className="mt-4"
          style={{ paddingLeft: "10px", marginBottom: "100px" }}
        >
          {ingredientsLoading ? (
            <>
              <Stack direction="row" spacing={3} alignItems="center">
                {/* Skeleton for Checkbox */}
                <Skeleton variant="rectangular" width={24} height={24} />

                {/* Skeleton for Ingredient Name */}
                <Skeleton
                  variant="text"
                  width={150}
                  height={24}
                  style={{ fontFamily: "Poppins" }}
                />

                {/* Skeleton for Amount and Unit */}
                <Skeleton
                  variant="text"
                  width={80}
                  height={24}
                  style={{ marginLeft: "auto" }}
                />
              </Stack>

              <Skeleton
                variant="rectangular"
                height={1}
                width="100%"
                style={{ backgroundColor: "#CFCFCF", marginTop: "8px" }}
              />
            </>
          ) : (
            ingredients.map((aisleData, index) => (
              <React.Fragment key={aisleData.aisle}>
                {" "}
                {/* Use a unique key based on aisle */}
                <Typography
                  className="mb-4"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {aisleData.aisle}
                </Typography>
                <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  {aisleData.ingredients.map((ingredient, idx) => (
                    <React.Fragment key={`${aisleData.aisle}-${idx}`}>
                      {" "}
                      {/* Unique key based on aisle and index */}
                      <Stack direction="horizontal" gap={3}>
                        {/* <Form.Check
                          type="checkbox"
                          style={{ borderColor: "#4caf50" }}
                        /> */}
                        <FancyCheckbox />
                        <span
                          className="ms-4"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {ingredient.name}
                        </span>
                        <span className="ms-auto">
                          {Number(ingredient.amount)} {ingredient.unit}
                        </span>
                      </Stack>
                      <hr
                        className="mt-2 px-4"
                        style={{ border: "1px solid #CFCFCF" }}
                      />
                    </React.Fragment>
                  ))}
                </Row>
              </React.Fragment>
            ))
          )}
        </Row>

        <Row
          style={{
            // marginTop: 20,
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            boxShadow: "0px 5px 5px 10px #f0f0f0",
            backgroundColor: "#FFFEF7",
            position: "fixed",
            bottom: 0,
            width: "100%",
            left: 0,
            right: 0,
            margin: 0,
          }}
        >
          {/* <Stack
            direction="horizontal"
            className="footer align-items-center text-center"
            gap={3}
          > */}
          <ButtonPrimary
            className="w-100"
            text="Comandă acum"
            onClick={() => setShow(true)}
          />
          {/* <Box
              className="p-2"
              sx={{
                width: "44px",
                height: "44px",
                backgroundColor: "#FF2C64",
                borderRadius: "4px",
                border: "1px solid #FF2C64",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer", // Add cursor pointer
              }}
            >
              <DeleteIcon fontSize="medium" sx={{ color: "#fff" }} />
              
            </Box> */}
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
                Livrare ingrediente
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
              Vrei să ți livrăm alimentele acasă? Te vom contacta în scurt timp
              pentru a confirma detaliile comenzii printr un mesaj pe WhatsApp.
            </Modal.Body>
            <Modal.Footer className="p-2">
              <div
                style={{
                  gap: "10px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <ButtonPrimary
                  text={modalLoading ? <Spinner /> : "Da"}
                  onClick={handleEmail}
                />
                <BlackButton text="Nu" onClick={handleClose} />
              </div>
            </Modal.Footer>
          </Modal>
          {/* <Box
              className="p-2"
              sx={{
                width: "44px",
                height: "44px",
                backgroundColor: "#FF2C64",
                borderRadius: "4px",
                border: "1px solid #FF2C64",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer", // Add cursor pointer
              }}
            >
              <AddIcon fontSize="medium" sx={{ color: "#fff" }} />
            </Box> */}
          {/* </Stack> */}
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        style={{
          backgroundColor: "#E7EFDF",
        }}
      >
        {" "}
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
              // justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                border: "1px solid #000",
                color: "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandări
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>

          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
                marginRight: 10,
                borderRadius: "20px", // Rounded corners
                boxShadow: "0 4 30 rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Cumpărături
              </Typography>

              <Row className="mt-4">
                {ingredientsLoading ? (
                  <>
                    <Stack direction="row" spacing={3} alignItems="center">
                      {/* Skeleton for Checkbox */}
                      <Skeleton variant="rectangular" width={24} height={24} />

                      {/* Skeleton for Ingredient Name */}
                      <Skeleton
                        variant="text"
                        width={150}
                        height={24}
                        style={{ fontFamily: "Poppins" }}
                      />

                      {/* Skeleton for Amount and Unit */}
                      <Skeleton
                        variant="text"
                        width={80}
                        height={24}
                        style={{ marginLeft: "auto" }}
                      />
                    </Stack>

                    <Skeleton
                      variant="rectangular"
                      height={1}
                      width="100%"
                      style={{ backgroundColor: "#CFCFCF", marginTop: "8px" }}
                    />
                  </>
                ) : (
                  ingredients.map((aisleData, index) => (
                    <div key={index}>
                      <Typography
                        className="mb-4"
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {aisleData.aisle}
                      </Typography>
                      {aisleData.ingredients.map((ingredient, idx) => (
                        <React.Fragment key={idx}>
                          <Stack direction="horizontal" gap={3}>
                            {/* <Form.Check
                              type="checkbox"
                              style={{
                                boxShadow:
                                  "  box-shadow: 0 0 0 0.25rem rgba(255, 44, 100, 0.25);",
                                color: "#FF2C64",
                              }}
                              color="#FF2C64"
                            /> */}
                            {/* <Form.Check.Input
                              type="checkbox"
                              style={{ color: "#000" }}
                            /> */}
                            <FancyCheckbox />

                            <span
                              className="ms-4"
                              style={{ fontFamily: "Poppins" }}
                            >
                              {ingredient.name}
                            </span>
                            <span className="ms-auto">
                              {Number(ingredient.amount)} {ingredient.unit}
                            </span>
                          </Stack>
                          <hr
                            className="mt-2 px-4"
                            style={{ border: "1px solid #CFCFCF" }}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  ))
                )}
              </Row>

              <Row style={{}} className="mt-4">
                <Stack direction="horizontal" gap={2} className="w-100">
                  <ButtonPrimary
                    text="Comandă acum"
                    onClick={() => setShow(true)}
                  />
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                      >
                        Livrare ingrediente
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                    >
                      Vrei să ți livrăm alimentele acasă? Te vom contacta în
                      scurt timp pentru a confirma detaliile comenzii printr-un
                      mesaj pe WhatsApp.
                    </Modal.Body>
                    <Modal.Footer>
                      <div
                        style={{
                          gap: "10px",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <ButtonPrimary
                          text={modalLoading ? <Spinner /> : "Da"}
                          onClick={handleEmail}
                        />
                        <BlackButton text="Nu" onClick={handleClose} />
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* <div className="ms-auto d-flex gap-3">
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#FF2C64",
                        borderRadius: "4px",
                        // border: "1px solid #FF2C64",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer", // Add cursor pointer
                      }}
                    >
                      <DeleteIcon fontSize="medium" sx={{ color: "#fff" }} />
                      
                    </Box>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#FF2C64",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AddIcon sx={{ color: "#fff" }} />
                    </Box>
                  </div> */}
                </Stack>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default ShoppingList;
