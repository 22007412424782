import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Stack, Modal, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";
import BlackButton from "../buttons/BlackButton";
import axios from "axios";
import Cookies from "js-cookie";

const ShoppingListCard = ({ ingredients, email }) => {
  const ingredientNames = ingredients.map((item) => item.ingredient.name);
  const amountUnits = ingredients.map(
    (item) =>
      `${parseFloat(item.amount).toFixed(2).replace(/\.00$/, "")} ${item.unit}`
  );

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = Cookies.get("authToken");

  const handleEmail = () => {
    setModalLoading(true);
    let query = `
    mutation ShoppingListFeedbackMutation($email: String!) { shoppingListFeedback( email: $email) { success error } }`;
    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query: query, variables: { email: email } },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("a mers");
      })
      .catch((error) => {
        console.log("nu a mers");
      })
      .finally(() => {
        setModalLoading(false);
        handleClose();
      });
  };

  return (
    <Card
      style={{
        fontFamily: "Poppins",
        maxHeight: "400px",
        display: "flex", // Make the card a flex container
        flexDirection: "column", // Stack children vertically
        backgroundColor: "#FFFEF7",
      }}
      className="shadow-sm mt-4"
    >
      <Card.Body style={{ flex: "1 1 auto", overflowY: "auto" }}>
        <Stack gap={2} direction="horizontal">
          <Typography sx={{ fontFamily: "Poppins", fontWeight: "600" }}>
            Listă de cumpărături
          </Typography>
          <Button
            onClick={() => navigate("/dashboard/shopping-list")}
            className="ms-auto"
            style={{
              background: "none",
              border: "none",
              color: "#FF2C64",
              textDecoration: "underline",
            }}
          >
            Vezi tot
          </Button>
        </Stack>
        <hr style={{ border: "1px solid #CFCFCF" }} />
        {ingredientNames &&
          ingredientNames.map((name, index) => (
            <React.Fragment key={index}>
              <Row className="align-items-center mb-3 mt-4">
                <Col xs="auto">
                  <Form.Check type="checkbox" />
                </Col>
                <Col>
                  <span>{name}</span>
                </Col>
                <Col className="text-right">
                  <span>{amountUnits[index]}</span>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #CFCFCF" }} />
            </React.Fragment>
          ))}
        <Typography
          className="text-center"
          style={{
            background: "none",
            border: "none",
            color: "#FF2C64",
            textDecoration: "underline",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard/shopping-list")}
        >
          Vezi tot
        </Typography>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button
          style={{
            border: "none",
            backgroundColor: "#FF2C64",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          className="w-100"
          onClick={() => handleShow()}
        >
          Comandă acum
        </Button>
      </Card.Footer>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
            Livrare ingrediente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
          Vrei să ți livrăm alimentele acasă? Te vom contacta în scurt timp
          pentru a confirma detaliile comenzii printr-un mesaj pe WhatsApp.
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              gap: "10px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ButtonPrimary
              text={modalLoading ? <Spinner /> : "Da"}
              onClick={handleEmail}
            />
            <BlackButton text="Nu" onClick={handleClose} />
          </div>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default ShoppingListCard;
