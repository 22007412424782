import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  Form,
  Button,
  Spinner,
} from "react-bootstrap"; // Import Bootstrap components
import { Typography, Box, Stack, Alert, TextField } from "@mui/material"; // Import MUI components
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { fontFamily, styled, useMediaQuery } from "@mui/system";
import imagine from "./imagine.jpeg";
import Tick from "../../components/buttons/Tick";
import { AuthContext } from "../../context/AuthContext";

// const stripePromise = loadStripe(
//   "pk_live_51Q4m19FNee4CoG4rCwSySPQEi9j39QAkbbWlSULFUkW5fogDbck8oWZqaqOReo6npPKO2VXcnLEopkYHm2UnwEfI00x4pXZiXO"
// );
const stripePromise = loadStripe(
  "pk_live_51Q4m19FNee4CoG4rCwSySPQEi9j39QAkbbWlSULFUkW5fogDbck8oWZqaqOReo6npPKO2VXcnLEopkYHm2UnwEfI00x4pXZiXO"
);

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const StripePayment = () => {
  const elementStyle = {
    style: {
      base: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        color: "#32325d",
        "::placeholder": { color: "#d1d1d1" },
      },
      complete: { color: "#4caf50" },
      invalid: { color: "#ff5252" },
    },
  };
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [couponCode, setCouponCode] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);
  const [amount, setAmount] = useState(5000); // Default amount in cents
  const { user, productIndex } = location.state || {};
  const [product, setProduct] = useState(null);
  const { login } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    county: "",
    postalCode: "",
    country: "",
  });

  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
  };

  useEffect(() => {
    console.log(productIndex, user)
    const fetchProduct = async (productIndex) => {
      try {
        const response = await axios.post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
          {
            query: `
              query ($productIndex: Int!) {
                stripeProducts(productIndex: $productIndex) {
                  id
                  name
                  description
                  priceId
                  price
                  currency
                  recurring
                }
              }
            `,
            variables: {
              productIndex: productIndex,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const products = response.data.data.stripeProducts;

        if (products && products.length > 0) {
          setProduct(products[0]); // Adjust as needed if `stripeProducts` returns multiple items
          setAmount(products[0].price * 100);
        } else {
          setError("Product not found.");
        }
      } catch (error) {
        setError("An error occurred while fetching the product.");
      }
    };

    fetchProduct(2 - productIndex);
  }, [productIndex]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          line1: address.line1, // Replace with your actual data for line1, line2, etc.
          line2: address.line2,
          city: address.city,
          state: address.state,
          postal_code: address.postalCode,
          country: address.country,
        },
        name: user.fullname,
        email: user.email,
      },
      metadata: {
        county: region,
      },
    });

    if (error) {
      setError(error.message);
      setIsProcessing(false);
      return;
    }

    try {
      // Define coupon codes if needed
      let couponCodeId = "";
      if (couponCode === "BLOOM3") {
        couponCodeId = "t43IQg7f";
      } else if (couponCode === "BLOOM10") {
        couponCodeId = "7djhpTUB";
      } else if (couponCode === "ALEXIA10") {
        couponCodeId = "ad1MAvtW";
      } else if (couponCode === "IOANA10") {
        couponCodeId = "m36rxYn0";
      } else if (couponCode === "RAMONA10") {
        couponCodeId = "T0OsBlAD";
      } else if (couponCode === "TEODORA10") {
        couponCodeId = "aN2DsAk4";
      } else if (couponCode === "OANA10") {
        couponCodeId = "pNhy9F7S";
      } else if (couponCode === "CLARA10") {
        couponCodeId = "eKkjmnoo";
      } else if (couponCode === "BF15") {
        couponCodeId = "VErzmN6D";
      } else if (couponCode === "ANDREEA10") {
        couponCodeId = "MbUrsgbX";
      } else if (couponCode === "ALEXANDRA10") {
        couponCodeId = "Zh6C3Yjx";
      } else if (couponCode === "IULIKKA10") {
        couponCodeId = "xw0xIGgX";
      } else if (couponCode === "ORSI10") {
        couponCodeId = "vs6y47kh";
      } else if (couponCode === "ALEXANDRAB10") {
        couponCodeId = "OwuCwfty";
      } else if (couponCode === "IONELA10") {
        couponCodeId = "pHN8ZmVZ";
      } else if (couponCode === "NOI10") {
        couponCodeId = "z3VUzaR7";
      } else if (couponCode === "CLARABLOOM10") {
        couponCodeId = "OJk4Efif";
      } else if (couponCode === "OANAM10") {
        couponCodeId = "LnNZE42I";
      }

      // Prepare the GraphQL mutation
      const mutation = `
      mutation {
        registerAndCreateSubscription(
          username: "${user.username}",
          fullname: "${user.fullname}",
          email: "${user.email}",
          phoneNumber: "${user.phone_number}",
          age: "${user.age}",
          gender: "${user.gender}",
          alimentationType: "${user.alimentation_type}",
          energy: ${user.energy},
          stress: ${user.stress},
          pcos: ${user.pcos},
          endomethriosis: ${user.endomethriosis},
          bloatingFree: ${user.bloatingFree},
          constipationFree: ${user.constipationFree},
          lactoseFree: ${user.lactoseFree},
          glutenFree: ${user.glutenFree},
          foodsToBeExcluded: "${user.foods_to_be_excluded}",
          time: "${user.time}",
          mealPreference: "${user.meal_preference}",
          kcal: ${user.kcal},
          plan: ${user.plan},
          password: "${user.password}",
          priceId: "${product.priceId}",
          paymentMethodId: "${paymentMethod.id}"
          ${couponCodeId ? `, couponCode: "${couponCodeId}"` : ""},
          addressLine1: "${address.line1}",
          addressLine2: "${address.line2}",
          city: "${address.city}",
          postalCode: "${address.postalCode}",
          country: "${address.country}",
          county: "${region}"
        ) {
          token
        }
      }
    `;

      // Execute the GraphQL request
      const response = await axios.post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query: mutation },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Extract the token and handle login
      console.log(response.data)
      let token = response.data.data.registerAndCreateSubscription.token;
      login(token); // Call your login function with the token

      // Optional: Handle successful navigation or other post-purchase logic here
    } catch (error) {
      console.log(error)
      setError(error);
    } finally {
      setIsProcessing(false);
    }
  };
  
    return (
      <Container
        fluid
        style={{
          minWidth: "100%",
          padding: 0,
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Row>
          <Col
            className="d-none d-lg-block"
            lg={4}
            xs={12}
            style={{ padding: 0 }}
          >
            <div
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "100vh",
                width: "100%",
                padding: 0,
                fontFamily: "Poppins",
                overflowX: "hidden",
                overflowY: "hidden",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Container
                style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
              >
                <Row className="align-items-center text-white">
                  <Col lg={10}>
                    <Logo
                      sx={{ color: "#fff", fontFamily: "Poppins" }}
                      variant="h6"
                    >
                      bloomplate*
                    </Logo>

                    <p
                      style={{ fontWeight: 500 }}
                      className="lead mt-5 mb-3 subtitle"
                    >
                      Bloomplate este platforma care simplifică mâncatul sănătos
                      ca tu să poți avea un stil de viață echilibrat.
                    </p>
                    <p className="mt-5" style={{ fontWeight: 300 }}>
                      Cu Bloomplate vei avea:
                    </p>
                    <Card
                      style={{
                        padding: "16px",
                        backgroundColor: "transparent",
                        border: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      <Row>
                        <Col xs="auto">
                          <Tick />
                        </Col>
                        <Col>
                          <Card.Title
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#fff",
                            }}
                          >
                            Energie de dimineață până seara
                          </Card.Title>
                          <Card.Text
                            style={{ color: "#fff", fontSize: "12px" }}
                          >
                            Datorită alimentației sănătoase și echilibrate te
                            vei simții plin de energie și vitalitate în fiecare
                            zi.
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      style={{
                        padding: "16px",
                        backgroundColor: "transparent",
                        border: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      <Row>
                        <Col xs="auto">
                          <Tick />
                        </Col>
                        <Col>
                          <Card.Title
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#fff",
                            }}
                          >
                            Timp prețios pentru tine
                          </Card.Title>
                          <Card.Text
                            style={{ color: "#fff", fontSize: "12px" }}
                          >
                            O să scapi de stresul planificării meselor zilnice
                            și o să te bucuri de mai mult timp liber.{" "}
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      style={{
                        padding: "16px",
                        backgroundColor: "transparent",
                        border: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      <Row>
                        <Col xs="auto">
                          <Tick />
                        </Col>
                        <Col>
                          <Card.Title
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#fff",
                            }}
                          >
                            Plăcerea de a mânca fără griji
                          </Card.Title>
                          <Card.Text
                            style={{ color: "#fff", fontSize: "12px" }}
                          >
                            Mese delicioase și variate, care se potrivesc
                            perfect gusturilor și nevoilor tale specifice.{" "}
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col
            lg={8}
            xs={12}
            style={{
              backgroundColor: "#FFFDF2",
              height: "100vh",
              display: "block", // Change this to block to avoid flex alignment
              overflowY: "auto", // Enable vertical scrolling
              padding: 20, // Add padding for some spacing
            }}
          >
            <Row
              style={{
                padding: 20,
                marginBottom: "80px",
                marginLeft: !isMobile ? 80 : 0,
                marginTop: 40,
              }}
            >
              <Row>
                <Col lg={5}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    Detalii produs
                  </Typography>
                  <>
                    {product ? (
                      <>
                        <Typography
                          className="mb-4 mt-4"
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            color: "#000",
                          }}
                        >
                          Nume: {product.name}
                        </Typography>
                        <Typography
                          className="mb-4"
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            color: "#000",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "18px",
                              color: "#000",
                            }}
                          >
                            Beneficii incluse:
                          </Typography>
                          <Stack className="mt-4" direction="horizontal">
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#DCF552",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 4,
                              }}
                            >
                              <CheckIcon sx={{ color: "#000" }} />
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#000",
                              }}
                            >
                              50 + rețete noi în fiecare lună
                            </Typography>
                          </Stack>
                          <Stack className="mt-4" direction="horizontal">
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#DCF552",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 4,
                              }}
                            >
                              <CheckIcon sx={{ color: "#000" }} />
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#000",
                              }}
                            >
                              Plan alimentar personalizat
                            </Typography>
                          </Stack>
                          <Stack className="mt-4" direction="horizontal">
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#DCF552",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 4,
                              }}
                            >
                              <CheckIcon sx={{ color: "#000" }} />
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#000",
                              }}
                            >
                              Listă de cumpărături
                            </Typography>
                          </Stack>
                          <Stack className="mt-4" direction="horizontal">
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#DCF552",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 4,
                              }}
                            >
                              <CheckIcon sx={{ color: "#000" }} />
                            </Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#000",
                              }}
                            >
                              Opțiune de livrare a ingredientelor
                            </Typography>
                          </Stack>
                        </Typography>
                        <Typography
                          className="mb-4"
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            color: "#000",
                          }}
                        >
                          Pret: {product.price} RON
                        </Typography>
                      </>
                    ) : (
                      <p>Se incarca produsul</p>
                    )}
                  </>

                  <div>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "30px",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      className="mt-4"
                    >
                      Finalizează plata
                    </Typography>

                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="amount">
                        <Form.Label
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "16px",
                            color: "#4a4a4a",
                            fontWeight: "500",
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "20px",
                          }}
                        >
                          Suma (RON)
                        </Form.Label>
                        <Form.Control
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            color: "#32325d",
                            width: "100%",
                            padding: "10px",
                            borderRadius: "4px",
                            border: "1px solid #d1d1d1",
                            backgroundColor: "#f9f9f9",
                          }}
                          type="text"
                          value={(couponCode === "ALEXIA10" ||
                          couponCode === "IOANA10" ||
                          couponCode === "RAMONA10" ||
                          couponCode === "TEODORA10" ||
                          couponCode === "OANA10" ||
                          couponCode === "CLARA10" ||
                          couponCode === "ANDREEA10" ||
                          couponCode === "ALEXANDRA10" ||
                          couponCode === "IULIKKA10" ||
                          couponCode === "ORSI10" ||
                          couponCode === "ALEXANDRAB10" ||
                          couponCode === "IONELA10" ||
                          couponCode === "NOI10" ||
                          couponCode === "CLARABLOOM10" ||
                          couponCode === "OANAM10"
                            ? (amount / 100) * 0.9
                            : couponCode === "BF15"
                            ? (amount / 100) * 0.85
                            : amount / 100
                          ).toFixed(2)}
                          // fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="coupon">
                        <Form.Label
                          className="mt-4"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "16px",
                            color: "#4a4a4a",
                            fontWeight: "500",
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "20px",
                          }}
                        >
                          Cod cupon
                        </Form.Label>
                        <Form.Control
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "14px",
                            color: "#32325d",
                            width: "100%",
                            padding: "10px",
                            borderRadius: "4px",
                            border: "1px solid #d1d1d1",
                            backgroundColor: "#f9f9f9",
                          }}
                          type="text"
                          value={couponCode}
                          onChange={handleCouponChange}
                          variant="outlined"
                          placeholder="Enter your coupon code"
                          InputProps={{
                            // You can style the input if needed
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginBottom: "10px",
                          marginTop: "20px",
                        }}
                      >
                        Detalii card
                      </Form.Label>
                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Numărul cardului
                      </Form.Label>
                      <div
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <CardNumberElement options={elementStyle} />
                      </div>
                      <Row>
                        <Col md={6} xs={6} lg={6}>
                          <Form.Label
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "14px",
                              color: "#4a4a4a",
                              fontWeight: "500",
                              display: "block",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            Data de expirare
                          </Form.Label>
                          <div
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "14px",
                              color: "#32325d",
                              width: "100%",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "1px solid #d1d1d1",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <CardExpiryElement options={elementStyle} />
                          </div>
                        </Col>
                        <Col md={6} xs={6} lg={6}>
                          <Form.Label
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "14px",
                              color: "#4a4a4a",
                              fontWeight: "500",
                              display: "block",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            CVC-ul cardului
                          </Form.Label>
                          <div
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "14px",
                              color: "#32325d",
                              width: "100%",
                              padding: "10px",
                              borderRadius: "4px",
                              border: "1px solid #d1d1d1",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <CardCvcElement options={elementStyle} />
                          </div>
                        </Col>
                      </Row>

                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Adresă de facturare
                      </Form.Label>
                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Linia 1
                      </Form.Label>
                      <Form.Control
                        value={address.line1}
                        onChange={(e) =>
                          setAddress({ ...address, line1: e.target.value })
                        }
                        required
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                        placeholder="Adresa linia 1"
                      ></Form.Control>

                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Linia 2
                      </Form.Label>
                      <Form.Control
                        value={address.line2}
                        onChange={(e) =>
                          setAddress({ ...address, line2: e.target.value })
                        }
                        required
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                        placeholder="Adresa linia 2"
                      ></Form.Control>
                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Țara
                      </Form.Label>
                      <Form.Select
                        value={address.country}
                        onChange={(e) =>
                          setAddress({ ...address, country: e.target.value })
                        }
                        required
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <option value="">Alege țara</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo, Democratic Republic</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea, North</option>
                        <option value="KR">Korea, South</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>

                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        Cod poștal
                      </Form.Label>
                      <Form.Control
                        value={address.postalCode}
                        onChange={(e) =>
                          setAddress({ ...address, postalCode: e.target.value })
                        }
                        required
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                        placeholder="Cod poștal"
                      ></Form.Control>

                      <Form.Label
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#4a4a4a",
                          fontWeight: "500",
                          display: "block",
                          marginTop: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        Alege județul
                      </Form.Label>
                      <Form.Select
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                        required
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "#32325d",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          border: "1px solid #d1d1d1",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <option value="" disabled>
                          Alege județul
                        </option>
                        <option value="Alba">Alba</option>
                        <option value="Arad">Arad</option>
                        <option value="Argeș">Argeș</option>
                        <option value="Bacău">Bacău</option>
                        <option value="Bihor">Bihor</option>
                        <option value="Bistrița-Năsăud">Bistrița-Năsăud</option>
                        <option value="Botoșani">Botoșani</option>
                        <option value="Brăila">Brăila</option>
                        <option value="Brașov">Brașov</option>
                        <option value="București">București</option>
                        <option value="Buzău">Buzău</option>
                        <option value="Călărași">Călărași</option>
                        <option value="Cluj">Cluj</option>
                        <option value="Constanța">Constanța</option>
                        <option value="Dâmbovița">Dâmbovița</option>
                        <option value="Dolj">Dolj</option>
                        <option value="Galați">Galați</option>
                        <option value="Giurgiu">Giurgiu</option>
                        <option value="Gorj">Gorj</option>
                        <option value="Harghita">Harghita</option>
                        <option value="Hunedoara">Hunedoara</option>
                        <option value="Ialomița">Ialomița</option>
                        <option value="Iași">Iași</option>
                        <option value="Ilfov">Ilfov</option>
                        <option value="Maramureș">Maramureș</option>
                        <option value="Mehedinți">Mehedinți</option>
                        <option value="Mureș">Mureș</option>
                        <option value="Neamț">Neamț</option>
                        <option value="Olt">Olt</option>
                        <option value="Prahova">Prahova</option>
                        <option value="Sălaj">Sălaj</option>
                        <option value="Satu Mare">Satu Mare</option>
                        <option value="Sibiu">Sibiu</option>
                        <option value="Suceava">Suceava</option>
                        <option value="Teleorman">Teleorman</option>
                        <option value="Timiș">Timiș</option>
                        <option value="Tulcea">Tulcea</option>
                        <option value="Vaslui">Vaslui</option>
                        <option value="Vâlcea">Vâlcea</option>
                        <option value="Vrancea">Vrancea</option>
                      </Form.Select>

                      <Button
                        type="submit"
                        disabled={!stripe || isProcessing}
                        className="submit-button w-100 mt-3"
                        variant="primary"
                        text={isProcessing ? "Se incarca..." : "Plătește"}
                        style={{
                          backgroundColor: "#FF2C64",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: 400,
                          borderRadius: 10,
                          textTransform: "none",
                          padding: "8px 16px",
                          border: "none",
                        }}
                      >
                        {isProcessing ? "Processing..." : "Plătește"}
                      </Button>

                      {error && (
                        <Alert variant="danger" className="mt-3">
                          {error}
                        </Alert>
                      )}
                    </Form>
                  </div>
                  {error && <Alert variant="danger">{error}</Alert>}
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

const PaymentComponent = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment />
    </Elements>
  );
};

export default PaymentComponent;
