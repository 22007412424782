import React, { useState } from "react";

const FancyCheckbox = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div
            onClick={handleCheckboxClick}
            style={{
                display: "inline-block",
                width: "18px",
                height: "18px",
                borderRadius: "5px",
                border: "0.2px solid #ccc",
                cursor: "pointer",
                backgroundColor: isChecked ? "#FF2C64" : "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "background-color 0.3s ease",
            }}
        >
            {isChecked && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ width: "16px", height: "16px" }}
                >
                    <polyline points="20 6 9 17 4 12" />
                </svg>
            )}
        </div>
    );
};

export default FancyCheckbox;
