import { Avatar, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Stack,
  Spinner,
} from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import RecipeCard from "../../components/cards/RecipeCard";
import "./DashboardSidebar.css";
import YogurtCard from "../../components/cards/YogurtCard";
import ShoppingListCard from "../../components/cards/ShoppingListCard";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import ResponsiveNavbar from "../../components/navbar/ResponsiveNavbar";
import AddRecipeCard from "../../components/cards/AddRecipeCard";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import SkeletonRecipeCard from "../../components/cards/SkeletonRecipeCard";
import ShoppingListCardSkeleton from "../../components/cards/ShoppingListCardSkeleton";
import SkeletonYogurtCard from "../../components/cards/SkeletonYogurtCard";
import AssignmentIcon from "@mui/icons-material/Assignment";
const Dashboard = () => {
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [username, setUsername] = useState("");
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [meals, setMeals] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const token = Cookies.get("authToken");
  const navigate = useNavigate();
  const currentDate = new Date();
  const [deletedLoading, setDeletedLoading] = useState({
    micDejun: false,
    pranz: false,
    cina: false,
    snacks: false,
  });
  const { logout } = useContext(AuthContext);
  const dayNames = [];
  const [startWeekDate, setStartWeekDate] = useState("");
  const [endWeekDate, setEndWeekDate] = useState("");

  // Get the current date

  // Populate the dayNames array with the current day and the next six days
  for (let i = 0; i < 7; i++) {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i); // Add i days to the current date
    dayNames.push(nextDate.toLocaleString("default", { weekday: "long" }));
  }
  const dayMapping = {
    Sunday: "DUM",
    Monday: "LUN",
    Tuesday: "MAR",
    Wednesday: "MIE",
    Thursday: "JOI",
    Friday: "VIN",
    Saturday: "SAM",
  };
  // Get the current day index (0-6)
  const currentDayIndex = currentDate.getDay();
  let dayMappingActual = {};

  const days = Array.from({ length: 7 }, (_, i) => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + i);
    return nextDate.getDate(); // Get the day of the month (number)
  });

  for (let i = 0; i < 7; i++) {
    // const nextDate = new Date(currentDate);
    // nextDate.setDate(currentDate.getDate() + i);
    // const dayOfWeek = daysOfWeek[nextDate.getDay()];
    dayMappingActual[days[i]] = i + 1;
    // dayMappingActual[dayOfWeek] = i + 1;
  }

  const [selectedDay, setSelectedDay] = useState(1);
  const [ingredients, setIngredients] = useState([]);
  const [shoppingListLoading, setShoppingListLoading] = useState(false);
  const [mealsLoading, setMealsLoading] = useState(false);
  const [recommendationsLoading, setRecommendationsLoading] = useState(false);
  const [cachedData, setCachedData] = useState({});
  const [hasSetSelectedDay, setHasSetSelectedDay] = useState(false); // Track if selectedDay has been set
  const [hasWeekStartDate, setHasWeekStartDate] = useState(false);
  const [hasWeekEndDate, setHasWeekEndDate] = useState(false);
  const [email, setEmail] = useState("");
  const handleDayClick = (day) => {
    console.log(day);
    // If the day is already selected, deselect it
    setSelectedDay((prevDay) => (prevDay === day ? prevDay : day));
  };

  function getDaysInWeekWithRomanian(weekStartDate, weekEndDate) {
    // Romanian weekdays
    const romanianWeekdays = ["DUM", "LUN", "MAR", "MIE", "JOI", "VIN", "SÂM"];

    // Parse the input date strings into Date objects
    const startDate = new Date(weekStartDate);
    const endDate = new Date(weekEndDate);

    // Initialize arrays to hold the days and their Romanian names
    const daysArray = [];
    const romanianDaysArray = [];

    // Loop through each day from startDate to endDate
    let currentDate = startDate;
    while (currentDate <= endDate) {
      // Get the day of the month
      daysArray.push(currentDate.getDate());

      // Get the day of the week (0-6, where 0 is Sunday, 1 is Monday, etc.)
      const dayOfWeek = currentDate.getDay();

      // Get the Romanian name of the day
      romanianDaysArray.push(romanianWeekdays[dayOfWeek]);

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {
      daysArray, // Days of the month
      romanianDaysArray, // Days in Romanian
    };
  }

  const deleteClick = (mealId, mealType) => {
    // console.log(mealId);
    setDeletedLoading((prev) => ({
      ...prev,
      [mealType]: true,
    }));

    const query = `
      mutation {
        removeMeal(mealId: "${mealId}") {
          success
          error
        }
      }
    `;

    const cachedData =
      JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

    const mealsForDay = JSON.parse(cachedData.weekData.mealsForWeek)[
      selectedDay
    ];
    console.log(typeof mealsForDay, mealsForDay);

    // Step 3: Iterate through the meals for the day to find the meal by ID
    for (let category in mealsForDay) {
      console.log(category);
      if (mealsForDay[category] && mealsForDay[category].id === mealId) {
        // Remove the meal from the mealsForDay object
        delete mealsForDay[category];
        console.log("Removed meal from", category);
        break; // Exit loop once the meal is found and removed
      }
    }

    // Step 4: Update the cachedData with the modified mealsForDay
    cachedData.weekData.mealsForWeek = JSON.stringify({
      ...JSON.parse(cachedData.weekData.mealsForWeek),
      [selectedDay]: mealsForDay,
    });

    // Step 5: Store the updated cachedData back in sessionStorage
    sessionStorage.setItem("combinedUserData", JSON.stringify(cachedData));

    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        {
          query: query,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`, // Adjust the token prefix as necessary
          },
        }
      )
      .then((response) => {
        const { success, error } = response.data.data.removeMeal;
        if (success) {
          // navigate(0);
          setMeals(mealsForDay);
        } else {
          console.error(error); // Handle the error appropriately
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      })
      .finally(() => {
        setDeletedLoading((prev) => ({
          ...prev,
          [mealType]: false,
        }));
      });
  };

  useEffect(() => {
    const fetchAndHandleUserData = async () => {
      console.log("Token: ", token);

      setRecommendationsLoading(true);
      setShoppingListLoading(true);
      setMealsLoading(true);

      const cachedData =
        JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

      let weekData;

      if (cachedData.weekData) {
        console.log("Using cached data...");
        weekData = cachedData.weekData;
      } else {
        const query = `
          mutation {
            combinedUserDataAllTest {
              username
              email
              mealsForWeek
              shoppingList {
                ingredient { id name }
                amount
                unit
              }
              recommendations {
                id
                title
                thumbnail
              }
              currentDay
              weekStartDate
              weekEndDate
            }
          }
        `;
        console.log(query);
        try {
          const response = await axios.post(
            "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
            { query },
            {
              headers: {
                Authorization: `JWT ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          weekData = response.data.data.combinedUserDataAllTest;

          sessionStorage.setItem(
            "combinedUserData",
            JSON.stringify({ weekData })
          );
        } catch (error) {
          console.error("Error fetching user data: ", error);
          stopLoading();
          return;
        }
      }

      // Initialize state with fetched or cached data
      setSelectedDay((prev) => prev || weekData.currentDay);
      setStartWeekDate((prev) => prev || weekData.weekStartDate);
      setEndWeekDate((prev) => prev || weekData.weekEndDate);
      setEmail(weekData.email);

      // Update UI
      const mealsForWeek = JSON.parse(weekData.mealsForWeek);
      const dataForDay = mealsForWeek[selectedDay || weekData.currentDay];

      if (dataForDay) {
        updateUIWithFetchedData({
          ...weekData,
          mealsForDay: dataForDay,
        });
      }

      stopLoading();
    };

    if (token) {
      fetchAndHandleUserData();
    }
  }, [token]);

  // Update UI whenever `selectedDay` changes
  useEffect(() => {
    const cachedData =
      JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

    if (cachedData.weekData) {
      const mealsForWeek = JSON.parse(cachedData.weekData.mealsForWeek);
      const dataForDay = mealsForWeek[selectedDay];

      if (dataForDay) {
        updateUIWithFetchedData({
          ...cachedData.weekData,
          mealsForDay: dataForDay,
        });
      }
    }
  }, [selectedDay]);

  const updateUIWithFetchedData = (data) => {
    setUsername(data.username);
    setMeals(data.mealsForDay); // Set meals for the specific day
    setIngredients(data.shoppingList);
    setRecommendations(data.recommendations);
    setStartWeekDate(data.weekStartDate);
    setEndWeekDate(data.weekEndDate);
  };

  const stopLoading = () => {
    setRecommendationsLoading(false);
    setShoppingListLoading(false);
    setMealsLoading(false);
  };

  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFEF7" }}>
        <Row>
          <DashboardSidebar />
        </Row>
        <Row>
          <Col
            style={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              backgroundColor: "#FFFEF7",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)", // More pronounced box shadow
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Dashboard
            </Typography>
            <div
              className="mt-4 mb-2 ml-2 mr-2"
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                justifyContent: "space-between",
                display: "flex",
                // marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {Array.from({ length: 7 }, (_, index) => index + 1).map((day) => (
                <span key={day}>
                  {
                    getDaysInWeekWithRomanian(startWeekDate, endWeekDate)
                      .romanianDaysArray[day - 1]
                  }
                </span>
              ))}
            </div>
            <div
              style={{
                backgroundColor: "#F3A7BB",
                borderRadius: "60px",
                padding: "12px",
                paddingRight: "20px",
                paddingLeft: "20px",

                display: "flex",
                fontFamily: "Poppins",
                justifyContent: "space-between",
              }}
            >
              {Array.from({ length: 7 }, (_, index) => index + 1).map((day) => (
                <span
                  key={day}
                  onClick={() => handleDayClick(day)}
                  style={{
                    backgroundColor:
                      selectedDay === day ? "#FF2C64" : "transparent",
                    color: selectedDay === day ? "white" : "black",
                    borderRadius: "4px",
                    // padding: "8px 16px", // Adjusted padding for left and right
                    flex: 1, // Ensures equal space for each day
                    textAlign: "center", // Center the text
                  }}
                >
                  {
                    getDaysInWeekWithRomanian(startWeekDate, endWeekDate)
                      .daysArray[day - 1]
                  }
                </span>
              ))}
            </div>
          </Col>
        </Row>
        <Row
          className="mt-2"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <Col>
            <Typography
              className="mt-4 mb-2"
              sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              Mic Dejun
            </Typography>

            {deletedLoading.micDejun ? (
              // Show Spinner while delete operation is in progress
              <div className="text-center mt-2">
                <Spinner style={{ color: "#FF2C64" }} />

                <Typography className="mt-2" style={{ fontFamily: "Poppins" }}>
                  Se șterge rețeta...
                </Typography>
              </div>
            ) : mealsLoading ? (
              // Show Skeleton while data is loading
              <SkeletonRecipeCard isDesktop={false} />
            ) : meals["mic dejun"] && meals["mic dejun"].id ? (
              <RecipeCard
                handleDeleteClick={() =>
                  deleteClick(meals["mic dejun"].id, "micDejun")
                }
                day={selectedDay}
                meal_type={"mic dejun"}
                mealId={meals["mic dejun"].id}
                key={meals["mic dejun"].id}
                image={meals["mic dejun"].image_url}
                text={meals["mic dejun"].title}
                description={meals["mic dejun"].summary}
                time={meals["mic dejun"].ready_in_minutes}
                noIngredients={meals["mic dejun"].ingredients_count}
                isDesktop={false}
              />
            ) : (
              <AddRecipeCard day={selectedDay} mealType={"mic dejun"} />
            )}
            <Typography
              className="mt-4 mb-2"
              sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              Prânz
            </Typography>
            {deletedLoading.pranz ? (
              // Show Spinner while delete operation is in progress
              <div className="text-center mt-2">
                <Spinner style={{ color: "#FF2C64" }} />

                <Typography className="mt-2" style={{ fontFamily: "Poppins" }}>
                  Se șterge rețeta...
                </Typography>
              </div>
            ) : mealsLoading ? ( // Show Skeleton while data is loading
              <SkeletonRecipeCard isDesktop={false} />
            ) : meals["pranz"] && meals["pranz"].id ? (
              <RecipeCard
                handleDeleteClick={() =>
                  deleteClick(meals["pranz"].id, "pranz")
                }
                day={selectedDay}
                meal_type={"pranz"}
                mealId={meals["pranz"].id}
                key={meals["pranz"].id}
                image={meals["pranz"].image_url}
                text={meals["pranz"].title}
                description={meals["pranz"].summary}
                time={meals["pranz"].ready_in_minutes}
                noIngredients={meals["pranz"].ingredients_count}
                isDesktop={false}
              />
            ) : (
              <AddRecipeCard day={selectedDay} mealType={"pranz"} />
            )}
            <Typography
              className="mt-4 mb-2"
              sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              Cină
            </Typography>
            {deletedLoading.cina ? (
              // Show Spinner while delete operation is in progress
              <div className="text-center mt-2">
                <Spinner style={{ color: "#FF2C64" }} />
                <Typography className="mt-2" style={{ fontFamily: "Poppins" }}>
                  Se șterge rețeta...
                </Typography>
              </div>
            ) : mealsLoading ? ( // Show Skeleton while data is loading
              <SkeletonRecipeCard isDesktop={false} />
            ) : meals["cina"] && meals["cina"].id ? (
              <RecipeCard
                handleDeleteClick={() => deleteClick(meals["cina"].id, "cina")}
                day={selectedDay}
                meal_type={"cina"}
                mealId={meals["cina"].id}
                key={meals["cina"].id}
                image={meals["cina"].image_url}
                text={meals["cina"].title}
                description={meals["cina"].summary}
                time={meals["cina"].ready_in_minutes}
                noIngredients={meals["cina"].ingredients_count}
                isDesktop={false}
              />
            ) : (
              <AddRecipeCard day={selectedDay} mealType={"cina"} />
            )}
            <Typography
              className="mt-4 mb-2"
              sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
            >
              Snack
            </Typography>
            {deletedLoading.snacks ? (
              // Show Spinner while delete operation is in progress
              <div className="text-center mt-2">
                <Spinner style={{ color: "#FF2C64" }} />

                <Typography className="mt-2" style={{ fontFamily: "Poppins" }}>
                  Se șterge rețeta...
                </Typography>
              </div>
            ) : mealsLoading ? ( // Show Skeleton while data is loading
              <SkeletonRecipeCard isDesktop={false} />
            ) : meals["snack"] && meals["snack"].id ? (
              <RecipeCard
                handleDeleteClick={() =>
                  deleteClick(meals["snack"].id, "snacks")
                }
                day={selectedDay}
                meal_type={"snack"}
                mealId={meals["snack"].id}
                key={meals["snack"].id}
                image={meals["snack"].image_url}
                text={meals["snack"].title}
                description={meals["snack"].summary}
                time={meals["snack"].ready_in_minutes}
                noIngredients={meals["snack"].ingredients_count}
                isDesktop={false}
              />
            ) : (
              <AddRecipeCard day={selectedDay} mealType={"snack"} />
            )}
          </Col>
        </Row>
        <Row
          className="mt-5"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <Col className="text-center">
            {recommendationsLoading ? (
              <SkeletonYogurtCard />
            ) : (
              <YogurtCard recommendations={recommendations} />
            )}
          </Col>
        </Row>
        <Row
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
          }}
        >
          <Col>
            {shoppingListLoading ? (
              <ShoppingListCardSkeleton />
            ) : (
              <ShoppingListCard email={email} ingredients={ingredients} />
            )}
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        style={{
          backgroundColor: "#E7EFDF",
        }}
      >
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandări
            </Button>
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>

          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginRight: 10,
                borderRadius: "20px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Col className="mb-5" style={{ border: "1px solid #fff" }} lg={8}>
                <div style={{}}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    Dashboard
                  </Typography>
                  {/* TODO HERE */}
                  <div
                    className="mt-4 mb-2 ml-2 mr-2"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      justifyContent: "space-between",
                      display: "flex",
                      marginLeft: "10px",
                      marginRight: "10px",
                      paddingLeft: "40px",
                      paddingRight: "10px",
                    }}
                  >
                    {Array.from({ length: 7 }, (_, index) => index + 1).map(
                      (day) => (
                        <span key={day}>
                          {
                            getDaysInWeekWithRomanian(
                              startWeekDate,
                              endWeekDate
                            ).romanianDaysArray[day - 1]
                          }
                        </span>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#F3A7BB",
                      borderRadius: "60px",
                      // padding: "12px",
                      // paddingRight: "20px",
                      // paddingLeft: "20px",
                      display: "flex",
                      fontFamily: "Poppins",
                      justifyContent: "space-between",
                    }}
                  >
                    {Array.from({ length: 7 }, (_, index) => index + 1).map(
                      (day) => (
                        <span
                          key={day}
                          onClick={() => handleDayClick(day)}
                          style={{
                            backgroundColor:
                              selectedDay === day ? "#FF2C64" : "transparent",
                            color: selectedDay === day ? "white" : "black",
                            borderRadius: "20px",
                            height: selectedDay === day ? "40px" : "40px", // Adjust height when clicked
                            lineHeight: selectedDay === day ? "40px" : "40px", // Ensure text is centered vertically
                            flex: 1, // Ensures equal space for each day
                            textAlign: "center", // Center the text
                            transition: "height 0.2s ease", // Smooth transition effect
                          }}
                        >
                          {/* {day}
                           */}
                          {
                            getDaysInWeekWithRomanian(
                              startWeekDate,
                              endWeekDate
                            ).daysArray[day - 1]
                          }
                        </span>
                      )
                    )}
                  </div>
                </div>

                <Typography
                  className="mt-4 mb-2"
                  sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                >
                  Mic Dejun
                </Typography>

                {deletedLoading.micDejun ? (
                  // Show Spinner while delete operation is in progress
                  <div className="text-center mt-2">
                    <Spinner style={{ color: "#FF2C64" }} />

                    <Typography
                      className="mt-2"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Se șterge rețeta...
                    </Typography>
                  </div>
                ) : mealsLoading ? (
                  // Show Skeleton while data is loading
                  <SkeletonRecipeCard isDesktop={true} />
                ) : meals["mic dejun"] && meals["mic dejun"].id ? (
                  <RecipeCard
                    handleDeleteClick={() =>
                      deleteClick(meals["mic dejun"].id, "micDejun")
                    }
                    day={selectedDay}
                    meal_type={"mic dejun"}
                    mealId={meals["mic dejun"].id}
                    key={meals["mic dejun"].id}
                    image={meals["mic dejun"].image_url}
                    text={meals["mic dejun"].title}
                    description={meals["mic dejun"].summary}
                    time={meals["mic dejun"].ready_in_minutes}
                    noIngredients={meals["mic dejun"].ingredients_count}
                    isDesktop={true}
                  />
                ) : (
                  <AddRecipeCard day={selectedDay} mealType={"mic dejun"} />
                )}

                <Typography
                  className="mt-4 mb-2"
                  sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                >
                  Prânz
                </Typography>
                {deletedLoading.pranz ? (
                  // Show Spinner while delete operation is in progress
                  <div className="text-center mt-2">
                    <Spinner style={{ color: "#FF2C64" }} />

                    <Typography
                      className="mt-2"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Se șterge rețeta...
                    </Typography>
                  </div>
                ) : mealsLoading ? (
                  <SkeletonRecipeCard isDesktop={true} />
                ) : meals["pranz"] && meals["pranz"].id ? (
                  <RecipeCard
                    handleDeleteClick={() =>
                      deleteClick(meals["pranz"].id, "pranz")
                    }
                    day={selectedDay}
                    meal_type={"pranz"}
                    mealId={meals["pranz"].id}
                    key={meals["pranz"].id}
                    image={meals["pranz"].image_url}
                    text={meals["pranz"].title}
                    description={meals["pranz"].summary}
                    time={meals["pranz"].ready_in_minutes}
                    noIngredients={meals["pranz"].ingredients_count}
                    isDesktop={true}
                  />
                ) : (
                  <AddRecipeCard day={selectedDay} mealType={"pranz"} />
                )}

                <Typography
                  className="mt-4 mb-2"
                  sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                >
                  Cină
                </Typography>
                {deletedLoading.cina ? (
                  // Show Spinner while delete operation is in progress
                  <div className="text-center mt-2">
                    <Spinner style={{ color: "#FF2C64" }} />

                    <Typography
                      className="mt-2"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Se șterge rețeta...
                    </Typography>
                  </div>
                ) : mealsLoading ? (
                  <SkeletonRecipeCard isDesktop={true} />
                ) : meals["cina"] && meals["cina"].id ? (
                  <RecipeCard
                    handleDeleteClick={() =>
                      deleteClick(meals["cina"].id, "cina")
                    }
                    day={selectedDay}
                    meal_type={"cina"}
                    mealId={meals["cina"].id}
                    key={meals["cina"].id}
                    image={meals["cina"].image_url}
                    text={meals["cina"].title}
                    description={meals["cina"].summary}
                    time={meals["cina"].ready_in_minutes}
                    noIngredients={meals["cina"].ingredients_count}
                    isDesktop={true}
                  />
                ) : (
                  <AddRecipeCard day={selectedDay} mealType={"cina"} />
                )}

                <Typography
                  className="mt-4 mb-2"
                  sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                >
                  Snack
                </Typography>
                {deletedLoading.snacks ? (
                  // Show Spinner while delete operation is in progress
                  <div className="text-center mt-2">
                    <Spinner style={{ color: "#FF2C64" }} />

                    <Typography
                      className="mt-2"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Se șterge rețeta...
                    </Typography>
                  </div>
                ) : mealsLoading ? (
                  <SkeletonRecipeCard isDesktop={true} />
                ) : meals["snack"] && meals["snack"].id ? (
                  <RecipeCard
                    handleDeleteClick={() =>
                      deleteClick(meals["snack"].id, "snacks")
                    }
                    day={selectedDay}
                    meal_type={"snack"}
                    mealId={meals["snack"].id}
                    key={meals["snack"].id}
                    image={meals["snack"].image_url}
                    text={meals["snack"].title}
                    description={meals["snack"].summary}
                    time={meals["snack"].ready_in_minutes}
                    noIngredients={meals["snack"].ingredients_count}
                    isDesktop={true}
                  />
                ) : (
                  <AddRecipeCard day={selectedDay} mealType={"snack"} />
                )}
              </Col>
              <Col lg={4} style={{ marginTop: 60 }}>
                {recommendationsLoading ? (
                  <SkeletonYogurtCard />
                ) : (
                  <YogurtCard recommendations={recommendations} />
                )}
                {shoppingListLoading ? (
                  <ShoppingListCardSkeleton />
                ) : (
                  <ShoppingListCard email={email} ingredients={ingredients} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Dashboard;
